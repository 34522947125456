.dropend.btn-group {
	margin-left: auto;
}

.dropdown-toggle::after {
	border: 0 !important;
	margin-left: 0 !important;
}

.dropdown button {
	align-self: center;
}
#ver-actualizacion-general {
	font-size: 1.43em;
}