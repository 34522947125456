.dropend.btn-group {
	margin-left: auto;
}

.dropdown-toggle::after {
	border: 0 !important;
	margin-left: 0 !important;
}

.dropdown button {
	align-self: center;
}

#table-usuarios tr {
	vertical-align: middle;
}

.list-avatar {
	width: 30px;
	height: 30px;
	border-radius: 15px;
}

#table-usuarios tr.resaltado {
	background-color: #f8f8d4;
}