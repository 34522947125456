.box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
	border-radius: 10px;
	background-color: white;
	margin-top: 10px;
	border: 1px solid black;
}

.nombreCurso {
	font-weight: bold;
}