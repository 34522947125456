.container {
	text-align: center;
}

.avatar {
	width: 120px;
	height: 120px;
	border-radius: 50%;
}

.nombre {
	font-weight: bold;
	font-size: 20px;
}

table th,
table td {
	text-align: left;
	padding: 5px;
}
