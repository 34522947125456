html,
body,
#root,
main {
	height: 100%;
  	min-height: 100vh;
}

.section {
	transition: all 0.15s;
	margin-left: 64px;
}

.section.expanded {
	margin-left: 240px;
}

.section > div {
	height: 100%;
	min-height: 100vh;
}

.no-outline {
	outline: none;
}

.table-sm td {
	vertical-align: middle;
	padding: 4px;
}

.tooltip >.tooltip-inner {
	background-color: rgb(236, 230, 230);
	color: rgb(63, 1, 1);
	border: 2px solid #c91313;
}

.tooltip >.tooltip-arrow::before {
	border-left: 5px solid #c91313;
}

body .offcanvas-end {
	width: calc(100% - 69px);
}

body.menu-expanded .offcanvas-end {
	width: calc(100% - 245px);
}

button.btn-primary,
button.btn-secondary,
button.btn-outline-primary:hover,
button.btn-outline-secondary:hover {
	color: white !important;
}

.border-primary {
	border: 1px solid var(--bs-primary);
}